<template>
    <div class="navigationBar boxShadow">
        <el-menu @select="handleSelect" :default-active="active" :unique-opened="true">
            <el-menu-item index="CustomerList">
                <img v-if="active == 'CustomerList'" src="@/assets/supply/selectcustom.png" alt="">
                <img v-else src="@/assets/supply/custom.png" alt="">
                <span>客户列表</span>
            </el-menu-item>
            <el-menu-item index="ProductLibrary">
                <img v-if="active == 'ProductLibrary'" src="@/assets/supply/selectproduct.png" alt="">
                <img v-else src="@/assets/supply/product.png" alt="">
                <span>产品库</span>
            </el-menu-item>
            <el-menu-item index="SupplierInfo" :route="path">
                <img v-if="active == 'SupplierInfo'" src="@/assets/supply/selectenterprise.png" alt="">
                <img v-else src="@/assets/supply/enterprise.png" alt="">
                <span>企业信息</span>
            </el-menu-item>
            <el-menu-item index="WorkCard">
                <img v-if="active == 'WorkCard'" src="@/assets/supply/selectwork.png" alt="">
                <img v-else src="@/assets/supply/work.png" alt="">
                <span>工作牌</span>
            </el-menu-item>
            <el-submenu index="5">
                <template slot="title">
                    <img src="@/assets/supply/order.png" alt="">
                    <span>订单管理</span>
                </template>
                <el-menu-item index="OrderList">
                    <span>全部订单</span>
                </el-menu-item>
                <el-menu-item index="OrderListInProgress">
                    <span>进行中订单</span>
                </el-menu-item>
                <el-menu-item index="OrderListCompleted">
                    <span>已完成订单</span>
                </el-menu-item>
                <el-menu-item index="OrderListReturnOrder">
                    <span>退货单</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="6">
                <template slot="title">
                    <img src="@/assets/supply/personnel.png" alt="">
                    <span>人员管理</span>
                </template>
                <el-menu-item index="EmployeeList">
                    <span>员工列表</span>
                </el-menu-item>
                <el-menu-item index="PermissionBatchManagement">
                    <span>权限批量管理</span>
                </el-menu-item>
                <el-menu-item index="ApprovalInfo">
                    <span>人员审批</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="7">
                <template slot="title">
                    <img src="@/assets/supply/finance.png" alt="">
                    <span>财务管理</span>
                </template>
                <el-menu-item index="FinancialStatistics">
                    <span>财务统计</span>
                </el-menu-item>
                <el-menu-item index="DocTemplate">
                    <span>单据模版</span>
                </el-menu-item>
            </el-submenu>
            <el-menu-item index="InventoryRecords">
                <img v-if="active == 'InventoryRecords'" src="@/assets/supply/selectinventory.png" alt="">
                <img v-else src="@/assets/supply/inventory.png" alt="">
                <span>库存管理</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'NavigationBar',

    data() {
        return {
            catalogueList: [
                {
                    routeName: 'CustomerList',
                    name: '客户列表',
                    SelectedSrc: require('../../../assets/supply/selectcustom.png'),
                    NoSelectedSrc: require('@/assets/supply/custom.png'),
                },
                {
                    name: '产品库',
                    SelectedSrc: require('@/assets/supply/selectproduct.png'),
                    NoSelectedSrc: require('@/assets/supply/product.png'),
                },
                {
                    name: '企业信息',
                    SelectedSrc: require('@/assets/supply/selectenterprise.png'),
                    NoSelectedSrc: require('@/assets/supply/enterprise.png'),
                },
                {
                    routeName: 'WorkCard',
                    name: '工作牌',
                    SelectedSrc: require('@/assets/supply/work.png'),
                    NoSelectedSrc: require('@/assets/supply/work.png'),
                },
                {
                    name: '订单管理',
                    SelectedSrc: require('@/assets/supply/selectcustom.png'),
                    NoSelectedSrc: require('@/assets/supply/order.png'),
                },
                {
                    name: '人员管理',
                    SelectedSrc: require('@/assets/supply/selectpersonnel.png'),
                    NoSelectedSrc: require('@/assets/supply/personnel.png'),
                },
                {
                    name: '财务管理',
                    SelectedSrc: require('@/assets/supply/selectcustom.png'),
                    NoSelectedSrc: require('@/assets/supply/finance.png'),
                },
                {
                    name: '库存管理',
                    SelectedSrc: require('@/assets/supply/selectinventory.png'),
                    NoSelectedSrc: require('@/assets/supply/inventory.png'),
                },

            ],
            active: '',
            path: {}

        }
    },
    created() {
        let that = this;
        that.active = that.$route.name;
        console.log(that.$route.name);
        that.$http.findEnterpriseAuditStateAndInfo({
            applicantId: parseInt(that.$store.state.userId),
            token: that.$getStorage('token')
        }).then(res => {
            that.$setStorage('enterpriseStateInfo', res.data)
        })
        let { state, enterpriseId } = this.$getStorage('enterpriseStateInfo');
        switch (state) {
            case '000':
                this.path = { path: '/JoinEnterpriseList' }
                break;
            case '011'://入驻审核
                this.path = {
                    path: '/EditEnterpriseInfo',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: 'processJoin'
                        }))
                    }
                }
                break;
            case '012'://企业入驻审核不通过
                this.path = {
                    path: '/EditEnterpriseInfo',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: 'enterpriseSettleAuditFailed'
                        }))
                    }
                }
                break;
            case '031':
                this.path = {
                    path: '/EditEnterpriseInfo',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: 'process'
                        }))
                    }
                }
                break;
            case '032'://企业修改审核不通过
                this.path = {
                    path: '/EditEnterpriseInfo',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: 'processUpdateFailed'
                        }))
                    }
                }
                break;
            default://企业详情
                this.path = {
                    path: '/SupplierInfo',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: enterpriseId,
                            type: 'supply'
                        }))
                    }
                }
                break;
        }
    },
    methods: {
        handleSelect(e) {
            this.active = e;
            let { userPermission, enterpriseId, state } = this.$getStorage('enterpriseStateInfo');
            switch (e) {
                case 'CustomerList'://客户列表
                    userPermission.c_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/CustomerList')
                    break;
                case 'ProductLibrary'://产品库
                    userPermission.p_l == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/ProductLibrary')
                    break;
                case 'SupplierInfo'://企业信息
                    if (userPermission.e_l == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        switch (state) {
                            case '031':
                                this.$router.push({
                                    path: '/EditEnterpriseInfo',
                                    query: {
                                        key: this.$UrlEncode.encode(JSON.stringify({
                                            type: 'process'//审核
                                        }))
                                    }
                                })
                                break;
                            case '032'://企业修改审核不通过
                                this.$router.push({
                                    path: '/EditEnterpriseInfo',
                                    query: {
                                        key: this.$UrlEncode.encode(JSON.stringify({
                                            type: 'processUpdateFailed'//审核
                                        }))

                                    }
                                })
                                break;
                            default://企业详情
                                this.$router.push({
                                    path: '/SupplierInfo',
                                    query: {
                                        key: this.$UrlEncode.encode(JSON.stringify({
                                            id: enterpriseId,
                                            type: 'supply'
                                        }))
                                    }
                                })
                                break;
                        }
                    }
                    break;
                case 'WorkCard'://工作牌
                    this.$router.push({
                        name: 'WorkCard', query: {
                            key: this.$UrlEncode.encode(JSON.stringify({
                                type: 'WorkCard'
                            }))
                        }
                    })
                    break;
                case 'InventoryRecords'://库存管理
                    userPermission.i_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/InventoryRecords')
                    break;
                case 'OrderList'://全部订单
                    if (userPermission.o_m == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'OrderList', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'All'
                                }))
                            }
                        });
                    }
                    break;
                case 'OrderListInProgress'://进行中订单
                    if (userPermission.o_m == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'OrderListInProgress', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'InProgress'
                                }))
                            }
                        })
                    }
                    break;
                case 'OrderListCompleted'://已完成订单
                    if (userPermission.o_m == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'OrderListCompleted', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'Completed'
                                }))
                            }
                        })
                    }
                    break;
                case 'OrderListReturnOrder'://退货单
                    if (userPermission.o_m == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'OrderListReturnOrder', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'ReturnOrder'
                                }))
                            }
                        })
                    }
                    break;
                case 'FinancialStatistics':// 财务统计
                    if (userPermission.f_s == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'FinancialStatistics'
                        })
                    }
                    break;
                case 'DocTemplate':// 单据模板
                    if (userPermission.f_s == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'DocTemplate'
                        })
                    }
                    break;
                case 'EmployeeList':// 员工列表
                    if (userPermission.p_m == 'D') {
                        this.$common.message('暂无权限！', 'warning')
                    } else {
                        this.$router.push({
                            name: 'EmployeeList'
                        })
                    }
                    break;
                case 'PermissionBatchManagement':// 权限批量管理
                    if (userPermission.p_m == 'W' && userPermission.a_m == 'W') {
                        this.$router.push({
                            name: 'PermissionBatchManagement'
                        })

                    } else {
                        this.$common.message('暂无权限！', 'warning')
                    }
                    break;
                case 'ApprovalInfo':// 人员审批
                    if (userPermission.p_m == 'W') {
                        this.$router.push({
                            name: 'ApprovalInfo'
                        })
                    } else {
                        this.$common.message('暂无权限！', 'warning')
                    }
                    break;
            }

        }
    },
}

</script>
<style lang='less' scoped>
.navigationBar {
    margin-left: 1px;
    width: 260px;
    height: 50%;
    border-radius: 10px;
    padding: 30px px;


    .item {
        height: 40px;
        width: calc(100% - 60px);
        margin-bottom: 10px;
        cursor: pointer;
        padding: 0 30px;
        border-radius: 10px;

        img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }

        .name {
            line-height: 30px;
            font-size: 16px;
            color: #333333;
        }

    }

    .item:hover {
        background: #ecf5ff;
    }

    .el-menu {
        border: none;

        .el-menu-item {
            border-radius: 10px;

            img {
                margin-right: 10px;
            }
        }

        .el-submenu__title {
            img {
                margin-right: 10px;
            }
        }
    }
}
</style>
