<template>
    <div class="has-img">
        <el-upload class="avatar-uploader" action="#" :on-progress="uploadVideoProcess" :before-upload="beforeUploadVideo"
            :show-file-list="false" :http-request="UploadVideo">
            <div v-if="!videoFlag">
                <video muted v-if="(videoUrl && videoUrl != 'D')" :src="videoUrl" class="avatar video-avatar"
                    controls="controls">
                    您的浏览器不支持视频播放
                </video>
                <div v-else-if="videoUrl == '' || videoUrl == 'D'">
                    <div class="f-c-c" style="position: relative;">
                        <i class="el-icon-circle-plus avatar-uploader-icon"></i>
                        <div style="position: absolute;bottom: 30px;color: #999999;">点击上传视频</div>
                    </div>

                </div>

            </div>
            <el-progress v-else type="circle" :percentage="videoUploadPercent" style="margin-top: 7px">
            </el-progress>
        </el-upload>
        <i v-if="(videoUrl != '' && videoUrl != 'D')" @click="deleteVideo" class="delete-btn el-icon-close"></i>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        videoUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            videoFlag: false,
            isShowUploadVideo: false,
            videoUploadPercent: ''
        }
    },
    created() { },
    methods: {
        //上传前回调
        beforeUploadVideo(file) {
            var fileSize = file.size / 1024 / 1024 < 20; //控制大小
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(file.type) == -1 //控制格式
            ) {
                this.$common.message("请上传正确的视频格式", "warning");
                return false;
            }
            if (!fileSize) {
                this.$common.message("视频大小不能超过20MB", "warning");
                return false;
            }
            let url = URL.createObjectURL(file);
            var audioElement = new Audio(url);
            let playTime = 0;
            audioElement.addEventListener("loadedmetadata", function () {
                playTime = audioElement.duration; //playTime就是当前视频长度
            });
            if (playTime > 15) {
                this.$common.message("上传的视频不能超过15秒！", "warning");
                return false;
            }
            this.isShowUploadVideo = false;
        },
        //进度条
        uploadVideoProcess(file) {
            this.videoFlag = true;
            this.videoUploadPercent = file.percent.toFixed(0) * 1;
        },
        UploadVideo(params) {
            this.$emit('FormData', params, "videoUrl")
        },
        deleteVideo() {
            this.$emit('deleteUploadVideo')
        },
    },
}

</script>
<style lang='less' scoped>
.avatar-uploader-icon {

    border: 1px solid #E6E6E6 !important;
    border-radius: 10px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px !important;
    position: relative !important;
    overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
    border: 1px dashed #d9d9d9 !important;
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 32px;
    color: #0675F3;
    width: 300px;
    height: 178px;
    line-height: 178px;
}

.avatar {
    width: 300px;
    height: 178px;
    display: block;
}

.has-img {
    position: relative;

    .delete-btn {
        position: absolute;
        left: 310px;
        top: -15px;
        border: 1px solid #333;
        border-radius: 50%;
        padding: 2px;
        color: #333;
        background: #fff;
    }

    .delete-btn:hover {
        background: red;
        border: 1px solid red;
        color: #fff;
    }
}
</style>
