<template>
    <div class="search-box f-c-c">
        <div class="search flex">
            <div class="search-options">
                <!-- typeNameChange -->
                <span @click="typeNameChange('name')" :class="typeName == 'name' ? 'pitchOn' : 'NopitchOn'">搜名称</span>
                <span style="margin:0 30px;" @click="typeNameChange('num')"
                    :class="typeName == 'num' ? 'pitchOn' : 'NopitchOn'">搜编号</span>
            </div>
            <el-select class="select-box" v-model="select" placeholder="请选择" @change="changeProductType">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <div v-if="typeName == 'name'" class="f-c search-input">
                <input type="text" placeholder="请输入产品名称" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                    @keyup.enter="search" maxlength="10" />
                <el-button class="search-icon" @click="handleImageUploadBox"><i class="el-icon-camera"></i></el-button>
                <el-button class="search-icon2" @click="goToColourSearch"><img style="height: 22px;width: 22px;"
                        src="@/assets/colorSearchIcon.png" alt=""></el-button>
            </div>
            <div v-else-if="typeName == 'num'" class="f-c search-input">
                <input type="text" placeholder="请输入产品编号" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                    @keyup.enter="search" maxlength="10" />
                <el-button class="search-icon" @click="handleImageUploadBox"><i class="el-icon-camera"></i></el-button>
                <el-button class="search-icon2" @click="goToColourSearch"><img style="height: 22px;width: 22px;"
                        src="@/assets/colorSearchIcon.png" alt=""></el-button>
            </div>
            <el-button class="search-icon3" @click="search" icon="el-icon-search"></el-button>
        </div>

        <!-- 图片搜索 -->
        <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
            :ImageSearchtype="'productLibrary'" :key="new Date().getTime()">
        </ImageUpload>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        enterpriseId: {
            type: String,
            default: ''
        },
        // inputText: {
        //     type: String,
        //     default: ''
        // },
    },
    components: {
        ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
    },
    data() {
        return {
            inputText: '',
            city: [],
            searchResultPage: 1,//搜索结果页
            showHistoryBox: false,//是否显示历史记录盒子
            productLibraryNameHistoryList: [],// 商品名历史记录盒子
            productLibraryNumHistoryList: [], // 商品编号历史记录盒子
            showImageUploadBox: false,//展示图片上传盒子
            options: [],
            select: '',
            typeName: '',
            imageUploadTrends: {
                'position': 'absolute',
                'right': '220px',
                'top': '50px'
            },
            routeName: '',
        }
    },
    created() {
        this.routeName = this.$route.name;
        this.typeName = this.$getStorage('ProductLibrary_typeName') ? this.$getStorage('ProductLibrary_typeName') : 'name';
        this.findSearchProductTypeList();
        this.select = this.$getStorage('ProductLibrary_productType') ?? 0;
    },
    methods: {
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
                this.$setStorage('ProductTypeList', this.options);
            })
        },
        // 首页搜索
        search() {
            this.$removeStorage('fetchDataPage');
            this.$removeStorage('fetchDataSize');
            let typeName = this.typeName;

            if (typeName == 'name') {
                let historyList = this.$getStorage('productLibraryNameHistoryList') != null ? this.$getStorage('productLibraryNameHistoryList') : [];
                historyList.push(this.inputText);
                let List = [...new Set(historyList)];
                this.$setStorage('productLibraryNameHistoryList', List)
                this.$emit('handleSearchBox', this.inputText, this.select, 0);
            } else if (typeName == 'num') {
                let historyList = this.$getStorage('productLibraryNumHistoryList') != null ? this.$getStorage('productLibraryNumHistoryList') : [];
                historyList.push(this.inputText);
                let List = [...new Set(historyList)];
                this.$setStorage('productLibraryNumHistoryList', List)
                this.$emit('handleSearchBox', this.inputText, this.select, 1);
            }
            this.showHistoryBox = false;
        },
        // 获取焦点获取历史记录
        inputFocus() {
            let that = this;
            if (that.typeName == 'name') {
                let productLibraryNameHistoryList = that.$getStorage('productLibraryNameHistoryList') != null ? that.$getStorage('productLibraryNameHistoryList') : [];
                that.productLibraryNameHistoryList = productLibraryNameHistoryList;
                that.showHistoryBox = true
                that.showImageUploadBox = false
            }
            else if (that.typeName == 'num') {
                let productLibraryNumHistoryList = that.$getStorage('productLibraryNumHistoryList') != null ? that.$getStorage('productLibraryNumHistoryList') : [];
                that.productLibraryNumHistoryList = productLibraryNumHistoryList;
                that.showHistoryBox = true
                that.showImageUploadBox = false
            }

        },
        //失去焦点历史记录关闭
        inputBlur() {
            setTimeout(() => {
                this.showHistoryBox = false
            }, 500);
        },
        // 历史记录查询
        historySearch(value) {
            let that = this;
            if (that.typeName == 'product') {
                if (that.$route.name != 'search') {
                    this.$setStorage('searchProductInput', value);
                    that.$router.push({
                        name: 'search',
                        query: {
                            key: that.$UrlEncode.encode(JSON.stringify({
                                type: 'text',
                                select: that.select
                            }))
                        }
                    })
                } else {
                    this.$setStorage('searchProductInput', value);
                    that.$emit('handleSearchBox', value, that.select);
                }

            } else {
                if (that.$route.name != 'searchFirm') {
                    this.$setStorage('searchFirmInput', value);
                    this.$setStorage('searchFirmcity', this.city);

                    this.$setStorage('typeName', that.typeName);
                    that.$router.push({
                        name: 'searchFirm',
                        query: {
                            key: this.$UrlEncode.encode(JSON.stringify({
                                // inputText: value,
                                // typeName: that.typeName,
                                type: 'text',
                            }))
                        }
                    })
                } else {
                    this.$setStorage('searchFirmInput', value);
                    this.$setStorage('searchFirmcity', this.city);
                    this.$setStorage('typeName', that.typeName);
                    this.$emit('handleSearchBox', that.typeName, value, this.city);
                }

            }
        },
        // 清空历史记录
        deleteHistoryList() {
            let that = this;
            if (that.typeName == 'product') {
                if (!that.$getStorage('historyList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('historyList')
            } else if (that.typeName == 'firm') {
                if (!that.$getStorage('firmhistoryList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('firmhistoryList')
            } else if (that.typeName == 'address') {
                if (!that.$getStorage('addresshistoryList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('addresshistoryList')
            }

            that.showHistoryBox = false
            that.$common.message('历史记录已清空！', 'success')
        },
        // 展开图片上传盒子
        handleImageUploadBox() {
            this.$setStorage('isreload', true);
            this.showHistoryBox = false;
            this.showImageUploadBox = true;
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
        changeProductType(value) {
            this.$setStorage('ProductLibrary_productType', value);
            this.$emit('changeProductType', value);
        },
        // 跳转去色彩库
        goToColourSearch() {
            this.$router.push({
                name: 'ProductLibraryColoursearch',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        enterpriseId: this.enterpriseId,
                    }))
                }
            })
        },
        handleClose(tag) {
            let historyList = this.historyList;
            this.historyList = historyList.splice(historyList.indexOf(tag), 1);
            this.$setStorage('historyList', historyList);
            this.showHistoryBox = true;
        },
        firmhandleClose(tag) {
            let firmhistoryList = this.firmhistoryList;
            this.firmhistoryList = firmhistoryList.splice(firmhistoryList.indexOf(tag), 1);
            this.$setStorage('firmhistoryList', firmhistoryList);
            this.showHistoryBox = true;
        },
        addresshandleClose(tag) {
            let addresshistoryList = this.addresshistoryList;
            this.addresshistoryList = addresshistoryList.splice(addresshistoryList.indexOf(tag), 1);
            this.$setStorage('addresshistoryList', addresshistoryList);
            this.showHistoryBox = true;
        },
        // 选中省市区
        handleChangeCity(value) {
            this.city = value;
            this.$setStorage('HomeSearchCity', value)
        },
        typeNameChange(value) {
            this.typeName = value;
            this.inputText = '';
        },
        cleanInputText() {
            // console.log('cleanInputText');
            this.inputText = '';
            this.$removeStorage('ProductLibrarySearchValue')
        }
    },
}

</script>
<style lang='less' scoped>
.search-box {
    width: 1280px;
    position: relative;
    padding-bottom: 20px;

    .search {
        position: relative;

        .search-options {
            position: absolute;
            top: -30px;
            left: 50px;

            .pitchOn {
                border-bottom: 3px solid #0270F2;
                padding: 5px;
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }

            .NopitchOn {
                color: #999999;
                font-size: 14px;
                padding: 5px;
                cursor: pointer;
            }
        }


        .select-box {
            width: 110px;
            position: absolute;
            left: -130px;
        }

        .search-input {
            position: relative;

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 20px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 600px;
            }

            .search-icon {
                width: 50px;
                height: 38px;
                line-height: 40px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                right: 0;
                font-size: 20px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: 0;
            }

            .search-icon2 {
                width: 50px;
                height: 38px;
                line-height: 50px;
                position: absolute;
                right: 50px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: auto;
            }
        }

        .search-icon3 {
            width: 80px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            color: #FFFFFF;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        }

        :deep(.el-input__inner) {
            border: 1px solid #E0E1E3;
            // border-right: none !important;
        }

        .el-button {
            border: 1px solid #E0E1E3;
            border-left: none !important;
            border-radius: 0%;
        }

        el-button {
            border: none;
            margin-left: 5px;
        }
    }

    .search-container {
        position: absolute;
        top: 50px;
        right: 320px;
        z-index: 99;
        border-radius: 10px;

        .input-history {
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 1px;

            .delete-history {
                display: inline-block;
                padding: 1px 15px;
            }

            .history {
                margin-top: 5px;
                padding: 5px;
                flex-wrap: wrap;

                .history-item {
                    // border: 1px solid #000;
                    background: #ffffff;
                    border-radius: 15px;
                    // padding: 6px 10px;
                    font-size: 14px;
                    // color: #000;
                    margin: 10px 20px 0 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .history-no {
                    padding: 10px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
</style>
